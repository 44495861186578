@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.ian-label-set{
  position: relative;
  button{
    margin-top: 0.65em !important;
  }
}
.ian-form-group-label {
  color: $ian-color-label !important;
  font-family: $TruenoRg;
  font-size: 12px;
  position: relative;
  &.ian-form-group-label-inline-block{
    display: inline-block;
  }
}
.helperText {
  font-size: 12px;
  color: $ian-color-primary !important;
  margin-top: 8px;
}
.sendVerificationLink {
    font-size: 12px;
    color: $ian-color-primary !important;
    margin-top: 8px;
    margin-left: 16px;
    a {
      text-decoration: underline;
    }
  }

.ian-txt-tooltip{
  position: absolute !important;
  top: -18px;
  svg{
    width: 16px;
    height: auto;
  }
}
