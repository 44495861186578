.ian-autocomplete{
    .MuiOutlinedInput-root{
        background: #eef9f7;
        border-radius: 16px;
        ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari/Edge */
            color: rgba(0, 0, 0, 0.4);
          }
        .MuiAutocomplete-endAdornment{
            button{
                margin-top: 0;
            }
        }
        fieldset{
            border: 0;
        }
        
    }
    &[aria-expanded='true'], &.Mui-focused{
        .MuiOutlinedInput-root{
            border: 2px solid #156873;;
        }
    }
}


.MuiAutocomplete-popper{
    div{
        background: #eef9f7;
        font-size: 14px;
    }
}