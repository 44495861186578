$ian-color-primary: #156873;
$ian-color-primary-hover:#0e454d;
$ian-color-primary-variant: #eef9f7;
$ian-color-dark: #000;
$ian-color-white: #fff;
$ian-color-scondary: #fc987a;
$ian-color-scondary-hover: #e67a5a;
$ian-color-error: #ff4f00;
$ian-color-label: rgba(0,0,0,0.5);
$ian-color-link: #007bff;
$ian-color-light: #eef9f7;
$ian-color-primary-light-menu: #448e94;