@import "../../style/typography.scss";
@import "../../style/colors.scss";

.ian-title-h5{
  opacity: 0.3;
  font-family: $TruenoSBd;
  font-size: 16px;
  color: #000;
  padding: 12px 32px 32px 32px;
  text-transform: uppercase;
}
.ian-account-back-btn{
    border: none;
    box-shadow: none;
    background-color: transparent;
    height: 19px;
    opacity: 0.3;
    font-family: $TruenoSBd;
    font-size: 16px;
    color: #000;
    display: flex;
    margin-bottom: 24px;
    text-align: center;
}

.accntPrefMenuList {
    .MuiListItemButton-root{
        height: 19px;
        
        color: #156873;
        padding: 32px;
        &:hover{
            background-color: transparent;
        }
        .MuiTypography-root{
            font-family: $TruenoRg !important;
            font-size: 16px;
        }
    }
    .Mui-selected{
        background-color: $ian-color-primary-variant !important;
        border-right: 6px solid $ian-color-primary;
        .MuiTypography-root{
            font-family: $TruenoSBd !important;
            font-size: 16px;
        }
        @media only screen and (max-width: 600px) {
            &:hover{
                background-color: transparent;
            }
            border-right: 0;
            background-color: transparent;
        }
    }
}

.el20pW{
    flex-basis:20% !important;
}
.el80pW{
    flex-basis:80% !important;
    max-width: 80% !important;
}
.el1260W{
    max-width: 1260px !important;
}


.textFieldWthRmvBtn{
    width: calc(100% - 50px) !important;
    svg{
        position: relative;
        top: 41px;
        right: -25px;
    }
}

.custom-add-btn{
    position: relative;
    top: -45px;
    cursor: pointer;
}

.ian-toggle-custom-switch{
    .ian-switch{
        width: 135px;
        .slider.round{
            width: 60px;
            margin-left: 135px;
        }
        .ian-switch-label{
            white-space: unset;
        }
    }
}