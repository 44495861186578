.landingPadding{
    padding: 60px 0 40px 0;
    @media only screen and (max-width: 600px) {
        padding: 160px 0 40px 0; 
    }
}

.navBarMiddle{
    display: none;
}
  
.appBar{
    width: 100% !important;
    position: absolute !important;
    top: 0;
    z-index: 9;
}

.homeLogo.navBarLogo{
    width: 240px !important;  
}

.pt15{
    padding-top: 15%;
}

.ian-login-desc{
    font-size: 14px;
    text-align: center;
    color: #000;
    padding-top: 14px;
}

.ian-signIn-verticalMiddle{
    position: absolute;
    width: 100%;
    top: 40%;
}