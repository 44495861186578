    $fontPath: "../assets/fonts/";
    $TruenoSBd: 'Trueno SemiBold';
    $TruenoRg: 'Trueno Regular';

    @font-face {
    font-family: 'Trueno Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Regular'), url('#{$fontPath}TruenoRg.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Regular Italic'), url('#{$fontPath}TruenoRgIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno UltraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno UltraLight'), url('#{$fontPath}TruenoUltLt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno UltraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno UltraLight Italic'), url('#{$fontPath}TruenoUltLtIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Light';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Light'), url('#{$fontPath}TruenoLt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Light Italic'), url('#{$fontPath}TruenoLtIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno SemiBold'), url('#{$fontPath}TruenoSBd.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno SemiBold Italic'), url('#{$fontPath}TruenoSBdIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Bold'), url('#{$fontPath}TruenoBd.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Bold Italic'), url('#{$fontPath}TruenoBdIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Bold Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Bold Outline'), url('#{$fontPath}TruenoBdOl.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Bold Outline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Bold Outline Italic'), url('#{$fontPath}TruenoBdOlIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno ExtraBold'), url('#{$fontPath}TruenoExBd.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno ExtraBold Italic'), url('#{$fontPath}TruenoExBdIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno ExtraBold Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno ExtraBold Outline'), url('#{$fontPath}TruenoExBdOl.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno ExtraBold Outline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno ExtraBold Outline Italic'), url('#{$fontPath}TruenoExBdOlIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Black';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Black'), url('#{$fontPath}TruenoBlk.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Black Italic'), url('#{$fontPath}TruenoBlkIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Black Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Black Outline'), url('#{$fontPath}TruenoBlkOl.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno Black Outline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Black Outline Italic'), url('#{$fontPath}TruenoBlkOlIt.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno UltraBlack';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno UltraBlack'), url('#{$fontPath}TruenoUltBlk.woff') format('woff');
    }

    @font-face {
    font-family: 'Trueno UltraBlack Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno UltraBlack Italic'), url('#{$fontPath}TruenoUltBlkIt.woff') format('woff');
    }