.ian-autocomplete .searchcontainer {
    border: 1px solid gray;
    padding: 5px;
    width: 50%;
    border-radius: 10px;
}

.ian-autocomplete .search {
    line-height: 10px;
    width: 100%;
    outline: none;
    border: none;
}

.ian-autocomplete ul {
    list-style: none;
}

.ian-autocomplete ul li {
    cursor: pointer;
}

.ian-autocomplete hr{
    border-top: 1px solid rgba(216, 216, 216, 0.753);
}
.ian-autocomplete{
    position:relative;
    .ian-autocomplete-set{
        z-index: 2;
        position: absolute;
        width: 100%;
        overflow-x: hidden;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        ul{
            margin: 0;
            padding:0;
            width: 360px;
            
            overflow-y: scroll;
            max-height: 240px;
            // width: 100%;
            background-color: #fff;
            @media only screen and (max-width: 600px) {
                max-height: 160px;
                width:100%;
            }
            li{
                border: 0;
                font-size: 16px;
                padding: 6px 0;
                color: #156873;
                padding-left: 16px;
                &:hover{
                    background-color: #eef9f7;
                }
                &:first-child{
                    margin-top: 14px;
                }
                &:last-child{
                    margin-bottom: 14px;
                }
            }
        }
    }
    
}

.ian-autocompleteNoChck input{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-right: 25px !important;
    font-size: 16px;
}