@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.primaryColor{
    color: #156873;
}
button:focus{
    outline: none;
}
.mainMenuDrawer{
    .MuiPaper-root div > a li{
        font-family: $TruenoRg;
        font-size: 24px;
        color: $ian-color-primary-light-menu;
    }
    .hamburgerMenuSubList {
        padding-left: 16px;
        a > li{
            font-size: 16px !important;
            line-height: 16px !important;
            padding: 24px;
            display: block;
        }
    }
}
  .MuiPaper-root {
      width: 100%;
  }
.gotoIanaBtn{
    &:hover{
        background-color: transparent !important;
    }
    .goToLink{
        font-family: $TruenoRg !important;
        color: rgba(0,0,0,.3);
        font-size: 16px !important;
        img{
            margin-left: 12px;
        }
    }
}
.navbar-tab{
    padding: 10px;
    color: #156873 !important;
    &:hover{
        text-decoration: none;
        border-bottom: 3px solid #156873;
    }
}

.navBarLogo{
    width: 100px;
}
.navBarLogoAnthem{
    border-left: 2px solid #000;
    padding-left: 20px;
    margin-left: 20px;
}

.logoutBtn{
    margin: 30px 40px;
    width: calc(100% - 80px);
}

.ian-landing{
    @media only screen and (min-width: 601px) {
        .navBarLogo, .navBarLogoAnthem{
            display: none;
        }
    }
}

.activeMenu{
    color: $ian-color-primary !important;
    font-family: $TruenoSBd !important;
}

.navBarItems{
    a{
        padding:0px 0px;
        margin-top: 10px;
        margin-right: 10px;
        margin-left: 10px;
    }
}

.logoutbtnHeight{
    height: 10px;
    .ian-btn-label{
        margin-top: 2px;
    }
}