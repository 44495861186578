@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

.form-group-label-text-area {
  color: $ian-color-label !important;
  font-family: $TruenoRg;
  font-size: 12px;
  width: 50%;
}
.form-group-count-label {
    color: $ian-color-label !important;
    font-family: $TruenoRg;
    font-size: 12px;
    width: 50%;
    text-align: right;
  }
  .form-group-count-label_error {
    color: $ian-color-error !important;
    font-family: $TruenoRg;
    font-size: 12px;
    width: 50%;
    text-align: right;
  }

.helperText {
  font-size: 12px;
  color: $ian-color-primary !important;
  margin-top: 8px;
}

.pull-tab {
  height: 0px;
  width: 0px;
  border-top: 7px solid #156873;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  -webkit-transform: rotate(-45deg);
  position: absolute;
  bottom: -1px;
  right: -4px;
  pointer-events: none;
  z-index: 2;
}

.ian-textarea{
  min-height: 65px;
}