.tabSectionRight select {
    color: black !important;
    option{
        color: #000 !important;
    }

    &[disabled]{
        color: rgba(0, 0, 0, 0.2)  !important;
    }

    &:invalid {
        color: rgba(0, 0, 0, 0.2)  !important;
      }

    &.selected-disabled{
        color: rgba(0, 0, 0, 1) !important;
    }
  }
select[disabled]{
    cursor: auto;
}