@import "../../../style/typography.scss";
@import "../../../style/colors.scss";

button:focus {
  outline: none !important;
}
.ian-btn-primary {
  padding: 16px;
  line-height: 19px;
  font-family: $TruenoSBd;
  border-radius: 24px;
  color: $ian-color-white;
  background-color: $ian-color-primary;
  border: 0;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  &[disabled] {
    opacity: 0.4;
  }
  &:hover{
    background-color: $ian-color-primary-hover;
  }
}

.ian-btn-secondary {
  padding: 16px;
  line-height: 19px;
  font-family: $TruenoSBd;
  border-radius: 24px;
  letter-spacing: 1px;
  color: $ian-color-white;
  background-color: $ian-color-scondary;
  border: 0;
  font-size: 16px;
  text-transform: uppercase;
  &[disabled] {
    opacity: 0.4;
  }
  &:hover{
    background-color: $ian-color-scondary-hover;
  }
}

.ian-btn-cancel {
  padding: 0 16px;
  line-height: 0px;
  font-family: $TruenoRg;
  color: rgba(14, 29, 40, 0.5);
  background-color: #ffffff;
  border: 0;
  font-size: 14px;
}

.ian-logoutModal {
  .ian-btn-cancel{
  background-color: $ian-color-primary;
  color: #fff;
  padding: 16px 34px;
  line-height: 4px;
  border-radius: 24px;
  border: 1px solid #156873;
  width: unset !important;
  margin: 0 auto;
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  &:hover{
    color: $ian-color-primary;
    background-color: #ffffff;
    border-color: $ian-color-primary;
  }
}
}

.ian-btn-light {
  padding: 10px 30px;
  line-height: 16px;
  font-family: $TruenoSBd;
  letter-spacing: 1px;
  border-radius: 24px;
  color: $ian-color-primary;
  background-color: $ian-color-light;
  border: 0;
  font-size: 12px;
  text-transform: uppercase;
  &[disabled] {
    opacity: 0.4;
  }
}

.ian-btn-label {
    padding: 10px 30px;
    margin-top: 5px;
    line-height: 16px;
    font-family: "Trueno SemiBold";
    letter-spacing: 1px;
    border-radius: 24px;
    color: #156873;
    background-color: #fff;
    border: 1px solid #156873;
    font-size: 12px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  &[disabled] {
    opacity: 0.4;
  }
  &:hover:not([disabled]){
    background-color: #156873;
    color: #ffff;
  }
}

.ian-btn-back{
  padding: 8px 18px 8px 12px;
  line-height: 19px;
  font-family: $TruenoSBd;
  border-radius: 30px;
  color: $ian-color-white;
  background-color: transparent;
  border: 0;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  margin-left: 17px;
  margin-top: -11px;
  z-index: 9;
  transition: all 0.2s ease-in-out;
  &:hover{
    background-color: #f5f5f5;
  }
  span{
    font-size: 12px;
    color: #156873;
    font-family: "Trueno SemiBold";
    letter-spacing: 0;
    text-transform: uppercase;
    position: relative;
    top: 1px;
  }
  img{
    width: 13px;
    margin-right:3px;
  }
  
}