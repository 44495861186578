@import "../../style/typography.scss";
@import "../../style/colors.scss";

.generalContentSection {
    overflow: auto;
    border-radius: 40px !important;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 20px;
    margin-top: 40px;
    background:white;
    min-height: 610px;

    @media only screen and (max-width: 600px) {
        min-height: unset;
    }

    .borderRight{
        border-right: 2px solid $ian-color-primary-variant;
        @media only screen and (max-width: 600px) {
            border-right: none;
        }
    }
    .tabSectionRight{
        padding: 64px 84px 84px 84px !important;
        button{
            margin-top: 12px;
        }
        @media only screen and (max-width: 600px) {
            padding: 12px 9px 24px 9px;
        }
    }
}

.ian-title-h5{
  opacity: 0.3;
  font-family: $TruenoSBd;
  font-size: 16px;
  color: #000;
  padding: 12px 32px 32px 32px;
  text-transform: uppercase;
}
.ian-account-back-btn{
    border: none;
    box-shadow: none;
    background-color: transparent;
    height: 19px;
    opacity: 0.3;
    font-family: $TruenoSBd;
    font-size: 16px;
    color: #000;
    display: flex;
    margin-bottom: 24px;
    text-align: center;
}

.accntPrefMenuList {
    .MuiListItemButton-root{
        height: 19px;
        
        color: #156873;
        padding: 32px;
        &:hover{
            background-color: transparent;
        }
        .MuiTypography-root{
            font-family: $TruenoRg !important;
            font-size: 16px;
        }
    }
    .Mui-selected{
        background-color: $ian-color-primary-variant !important;
        border-right: 6px solid $ian-color-primary;
        .MuiTypography-root{
            font-family: $TruenoSBd !important;
            font-size: 16px;
        }
        @media only screen and (max-width: 600px) {
            &:hover{
                background-color: transparent;
            }
            border-right: 0;
            background-color: transparent;
        }
    }
}

.progress-loader-page{
    text-align: center;
    margin: 0 auto;
    margin-top: 25%;
}