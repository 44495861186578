@import "../../../../style/typography.scss";
@import "../../../../style/colors.scss";

.ian-title-h5{
  opacity: 0.3;
  font-family: $TruenoSBd;
  font-size: 16px;
  color: #000;
  padding: 12px 32px 32px 32px;
  text-transform: uppercase;
}
.ian-account-back-btn{
    border: none;
    box-shadow: none;
    background-color: transparent;
    height: 19px;
    opacity: 0.3;
    font-family: $TruenoSBd;
    font-size: 16px;
    color: #000;
    display: flex;
    margin-bottom: 24px;
    text-align: center;
}

.accntPrefMenuList {
    .MuiListItemButton-root{
        height: 19px;
        
        color: #156873;
        padding: 32px;
        &:hover{
            background-color: transparent;
        }
        .MuiTypography-root{
            font-family: $TruenoRg !important;
            font-size: 16px;
        }
    }
    .Mui-selected{
        background-color: $ian-color-primary-variant !important;
        border-right: 6px solid $ian-color-primary;
        .MuiTypography-root{
            font-family: $TruenoSBd !important;
            font-size: 16px;
        }
        @media only screen and (max-width: 600px) {
            &:hover{
                background-color: transparent;
            }
            border-right: 0;
            background-color: transparent;
        }
    }
}

.ian-logout .ian-btn-label{
    margin: 0 auto;
    display: block;
    margin-top: 40px;
 }
