.add-button{
    float: right !important;
    margin-top: 22px;
}
.view-icon{
    cursor: pointer;
    color: #06ca16;
}
.edit-icon{
    cursor: pointer;
    color: #156873;
}

.delete-icon{
    cursor: pointer;
    color: #aa0601;
}

.dtable-height-set{
    max-height: 350px;
}

.dtable-height-set{
    tr{
        th:first-child{
            width: 240px;
        }
    }
}

.status-label{
    color: rgba(0, 0, 0, 0.2);
}