@import "./typography.scss";
@import "./colors.scss";

html {
  box-sizing: border-box;
}
body {
  font-family: $TruenoRg;
  background-color: $ian-color-light;
}

button{
    cursor: pointer;
    &:disabled{
        cursor: auto;
    }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0;
}
.h5 {
  color: #156873;
  font-weight: 600 !important;
}
.h4, h4{
  font-family: $TruenoSBd !important;
}
button {
  cursor: pointer;
}
a{
  color: #0005f8 !important;
}
.ian-bg-primary-variant {
  background: $ian-color-primary-variant;
}
.ian-bg-white {
  background-color: white;
}
.bg-white{
    background-color: white;
}

.ian-centeredSection {
  margin: 0 auto;
  display: block;
}

@mixin ian-position($property) {
  position: $property;
}

.ian-positions-absolute {
  @include ian-position(absolute);
}

.ian-position-relative {
  position: relative;
}

.ian-position-verticalMiddle {
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 600px) {
  header{
    .MuiContainer-maxWidthLg{
      padding-left: 8px;
    }
  }
  .ian-position-verticalMiddleRmv {
    position: relative;
    transform: translate(-50%, -60%);
  }

  .ian-position-verticalMiddleRmv-inside {
    transform: translate(0px, 0px);
    position: relative;
    left: 0;
    top: 0;
  }
  .ian-positions-absoluteRemover {
    @include ian-position(relative);
  }
}

.ian-modal-width {
  background: $ian-color-white;
  border-radius: 25px;
  box-shadow: 0 10px 20px 0 rgb(21 104 115 / 15%);
  width: 413px;
  padding: 32px 25px;
  border: 0 !important;
    .form{
        margin-top: 32px;
    }
    .submit-btn{
        margin-top: 32px;
    }
    .modal-centered-para{
      color: rgba(14, 29, 40, 0.4);
      font-size: 16px;
      text-align: center;
      line-height: 22px;
    }
    .modalPara-mb{
      margin-bottom: 32px;
    }
    .ian-modal-typo{
      text-align: center;
      color: rgba(14, 29, 40, 0.4);
    }

    .error-label{
      font-size: 12px;
      padding-left: 16px;
      color: $ian-color-error;
      margin-top: 12px;
      margin-bottom: 8px;
    }
    
    .accessLink{
      font-size: 12px;
      text-align: center;
    }
    @media only screen and (max-width: 600px) {
      width: 310px !important;
      padding: 32px 20px !important;
    }

}

.ian-modal-title {
  font-family: $TruenoSBd;
  color: $ian-color-primary;
  text-align: center;
  margin-bottom: 12px;
  font-size: 32px;
}

.ian-h1-title {
  color: $ian-color-primary;
  font-size: 38px;
  margin-bottom: 16px;
  font-family: $TruenoSBd;
}
.ian-header-right-sec{
  .ian-h1-title{
    margin-bottom: 24px;
  }
  p.ian-p-primary{
    opacity: 0.7;
    margin-bottom: 32px;
  }
}


.modal-subheading{
  font-family: $TruenoRg;
  font-size: 12px;
  color: rgba(14, 29, 40, 0.6);
  text-align: center;
  margin-bottom: 20px;
  display: block;
}

.ian-p-primary {
  color: $ian-color-primary;
  font-size: 16px;
}

.notModal{
  .submit-btn{
    margin: 0;
    margin-top: -4px;
  }
  
  .ian-modal-title{
    margin-bottom: 24px;
  }
  
  .error-label{
    margin-top: -16px;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 600px) {
    .ian-modal-width{
      position: relative;
      transform: none;
      left: unset;
      top: unset;
      width: auto !important;
      margin: 100px 16px;
    }
  }
}
.forgot-passPara{
  margin-bottom: 16px;
}

.icn24arrow {
  width: 24px;
  height: 24px;
  margin: 0 0 0 14px;
  padding: 7px 8px 6px;
  object-fit: contain;
}

.ian-w-50 {
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.ian-w-40 {
  width: 70%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.ian-w-50-20 {
  width: calc((100% - 20px) / 2);
}
.ian-w-70 {
  width: 70%;
}
.ian-w-100 {
  width: 100%;
}
.ian-vw-100 {
  width: 100%;
}
.ian-h-100 {
  min-height: 100vh;
}
.ian-ht-100 {
  min-height: 100%;
}
.mh-550 {
  min-height: 616px;
  @media only screen and (max-width: 600px) {
    min-height: 580px;
  }
}
.ian-title {
  color: $ian-color-primary;
  margin-bottom: 28px;
  font-size: 24px;
  line-height: 28px;
  font-family: $TruenoSBd;
}
.ian-text-center {
  text-align: center;
}

.ian-grey-link {
  color: rgba(0, 0, 0, 0.3);
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  img {
    margin-left: 10px;
  }
}
.label-color{
  color: rgba(0, 0, 0, 0.5);;
}
.ian-form-group {
  margin-bottom: 12px;

  label {
    color: $ian-color-dark;
    margin-bottom: 8px;
    padding-left: 16px;
    display: block;
  }
  .ian-form-group-inputGroup {
    position: relative;
    .ian-inputField-error {
      position: absolute;
      right: 12px;
      top: 11px;
    }
  }
  .ian-inputField {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 14px 16px 15px 16px;
    border-radius: 16px;
    background-color: $ian-color-primary-variant;
    border: 0;
    font-size: 16px;
    font-family: $TruenoRg, Lato, arial;
    line-height: 19px;
    padding-right: 40px;
    // letter-spacing: 1px;

    color: $ian-color-dark;
    font-weight: 400;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari/Edge */
      color: rgba(0, 0, 0, 0.2);
    }
    .ian-inputField {
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 14px 40px 15px 16px;
        border-radius: 16px;
        background-color: $ian-color-primary-variant;
        border: 0;
        font-size: 16px;
        line-height: 19px;

        color: $ian-color-dark;
        font-weight: 700;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari/Edge */
            color: rgba(0, 0, 0, 0.2);
        }

        &:focus-visible {
            outline: $ian-color-primary;
        }
    }
  }
  .ian-form-group-coupled {
    display: flex;
    input:first-child {
      width: 100px;
      margin-right: 15px;
    }
  }
}

.ian-error-warning {
  background-color: $ian-color-error;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: $ian-color-white;
  p {
    padding: 14px 20px;
  }
}

.greyPara {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 700;
  font-size: 12px;
}

.show {
  display: block !important;
}

.ian-form-container-list {
  padding-top: 300px;
}

.ian-form-container {
  @media only screen and (max-width: 600px) {
    padding: 15px;
  }
}

.ian-checkbox {
  position: absolute;
  left: -9999px;
}

/* Hide the browser's default checkbox */
.ian-form-check {
  position: relative;
  &.ian-form-check {
    display: inline-block;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

// text me link the card

.roundedCornerCard {
  padding: 24px 24px 32px;
  border-radius: 40px;
  box-shadow: 0 10px 20px 0 rgba(21, 104, 115, 0.15);
  background-color: white;
}
.dashboard-featuredCard {
  background-color: #38858b;
  color: white;
  padding-bottom: 24px;
  
}
.img-responsive {
  max-width: 100%;
}
//removed insite card
.boxShaddowRemover {
  box-shadow: none !important;
}
.bgTransparent {
  background-color: transparent !important;
}
.bgPosBottom {
  bottom: 0;
}
.addYourEmp {
  color: white !important;
  @media only screen and (max-width: 600px) {
    padding-right: 0px !important;
  }
  p {
    font-size: 14px;
    min-height: 123px;
    @media only screen and (max-width: 600px) {
      min-height: auto;
    }
  }
  .ian-form-group{
    margin-bottom: 0;
  }
  .addYourEmp-heading{
    font-size: 32px;
    margin-bottom: 12px;
    @media only screen and (max-width: 600px) {
      margin-top:30px;
    }
  }
  button{
    margin-bottom: -8px;
    @media only screen and (max-width: 600px) {
      margin-bottom: 24px;
    }
  }
}
.Ajeem-identifier {
  margin: 0;
  > div:nth-child(3) {
    margin-left: 0 !important;
  }
  > div:nth-child(2) .cardBtnPos {
    .MuiCardActions-root:first-child{
      padding-bottom: 0;
    }
    .MuiCardActions-root:nth-child(2){
      padding-top: 0;
    }
  }
  .rightCardP {
    padding-right: 15px;
    padding-left: 30px !important;
    .mb-ht470 {
      min-height: 520px;
    }
    @media only screen and (max-width: 600px) {
      margin-top: 10px;
      margin-left: 15px;
      padding-right: 0px !important;
      padding-left: 16px !important;
      .mb-ht470 {
        min-height: 520px;
      }
    }
  }
}

.makeStyles-indicators-4 {
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.MuiTooltip-popper{
  text-align: center;
}

.css-mhc70k-MuiGrid-root{
  margin-top: 0% !important;
}

@media only screen and (max-width: 600px) {
  .pt-xs-0{
    padding-top: 0 !important;
  }
}

.searchBtn-wthSelect{
  position: relative;
  top: 20px;
  left: 15px;
}

.selectFieldCustomArrow{
  appearance:none;
  cursor: pointer;
  -webkit-appearance:none;
  -moz-appearance:none;
}

.selectFieldCustomArrowParent::after{
  background-image: url("../assets/images/left-arrow.png");
  content:" ";
  display: inline-block;
  width: 13px;
  height: 13px;
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  transform: rotate(270deg);
  position: absolute;
  top: 19px;
  right: 15px;
  pointer-events:none;
}

.ian-autocomplete{ 
  button{
      display: none;
  }
  .MuiOutlinedInput-root{
    padding-right: 10px !important;
  }
    &::after{
      background-image: url("../assets/images/left-arrow.png");
      content:" ";
      display: inline-block;
      width: 13px;
      height: 13px;
      background-size: 12px;
      background-position: center;
      background-repeat: no-repeat;
      border: none;
      transform: rotate(270deg);
      position: absolute;
      top: 22px;
      right: 15px;
      pointer-events:none;
    }
  
}

.progress-loader-custom{
  padding: 21px 15px;
  border-radius: 16px;
  background-color: $ian-color-primary-variant;
  span{
    background-color: #eee;
      span{background-color: $ian-color-primary}
  }
}

.circularProg{
  width: 100%;
  position: absolute;
  display: block;
  text-align: center;
  padding: 50px;
  min-height: 100px;
}

.ian-linear-progress {
  background-color: rgb(255 255 255) !important;
  height: 1.1px !important;
  span {
    background-color: #156873 !important;
  }
}

.circularProg-Update{
  text-align: center;
  margin-top: 50px;
}

.ian-IntegrationInstructionsIcon{
  position:absolute;
  right:0;
  cursor: pointer;
}

.contentPrvwHeader{
  background-color: $ian-color-primary !important;
}

.ian-preview-close-btn{
  position: absolute !important;
  right: 17px;
  border: 1px solid #eee !important;
  &:hover{
    background: #fff !important;
    SVG{
      color: $ian-color-primary;
    }
  }
}

.ian-w-160{
  width: 160px !important;
}

.ian-mt-13{
  margin-top: 8% !important;
}

.contentSection {
  height: calc(100vh - 200px);
  overflow: unset !important;
  border-radius: 40px !important;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 20px;
  margin-top: 40px;
  background:white;
  min-height: 580px;

  @media only screen and (max-width: 600px) {
      min-height: unset;
  }

  .borderRight{
      border-right: 2px solid $ian-color-primary-variant;
      @media only screen and (max-width: 600px) {
          border-right: none;
      }
  }
  &.ian-table-content{
    height: 580px;
    .tabSectionRight{
        overflow-y: auto !important;
        height: 526px;
        padding: 40px 40px 0px 40px;
    }
}
  .tabSectionRight{
      height: 580px;
      overflow-y: none;
      padding: 45px 40px 84px 40px;
      button{
          margin-top: 12px;
      }
      @media only screen and (max-width: 600px) {
          padding: 12px 9px 24px 9px;
      }
  }
}

.ian-title-style{
  font-size: 24px !important;
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $ian-color-primary-variant;
  border-radius: 10px;
  margin-top: 20px !important;
  margin-bottom: 20px;
  margin-right: 40px !important;
  padding-right: 30px !important;
  right: 30px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $ian-color-primary;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ian-inputField {
  &:focus-visible {
    outline-color: $ian-color-primary;
  }
}

.ian-modal-close {
  &:hover {
    color: #156873 !important;
  }
}